import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { fetchCustomRecipeList } from 'actions/CustomRecipes';
import { showPaywallModal } from 'actions/Paywall';
import ActionBar from 'components/Pasta/ActionBar';
import Button from 'components/Pasta/Button';
import Image from 'components/Image';
import Link from 'components/Link';
import { pstIcons } from 'constants/FontFaceCharacters';
import { ALL_PERSONAL_RECIPES } from 'constants/index';
import { CHECKOUT_SOURCES } from 'constants/Pro';

import { authUsernameSelector } from 'selectors/auth';
import { isProUser } from 'selectors/pro';
import cdn from 'util/cdn';
import classNames from 'util/classNames';
import { useIntersection } from 'hooks';

const CREATE_YOUR_OWN_RECIPE = "Create Your Own Recipe";

export const CustomRecipeCollection = props => {
  const {
    customRecipes: {
      loaded,
      recipes,
      pagination,
    },
    fetchCustomRecipeList,
  } = props;
  const count = pagination.totalRecords;
  const isEmpty = !count && loaded;
  useEffect(() => {
    // because GET_ITEM also sets loaded state, fetch CR when recipes length is 0 just to be sure.
    if (!loaded || recipes.length === 0) {
      fetchCustomRecipeList();
    }
  }, [fetchCustomRecipeList, loaded]);
  const cls = classNames({
    'custom-recipe-collection': true,
    'is-empty': isEmpty,
  });

  const handleCustomRecipeLocked = () => {
    props.showPaywallModal(true, CHECKOUT_SOURCES.customRecipe, null, null, {
      redirect: `/profile/${props.username}/collections/${ALL_PERSONAL_RECIPES}`,
    });
  };

  const onPaginationTrigger = () => {
    fetchCustomRecipeList();
  };

  const { ref: paginationRef } = useIntersection(
    onPaginationTrigger,
    {
      threshold: 0.5,
      intersectionRatio: 0.5,
    },
    {
      alwaysObserve: true,
    });

  return (
    <div className={cls}>
      <section className="banner flex-column justify-content-space-between align-items-center">
        <h1 className="title">{`All Personal Recipes`}</h1>
        <p className="count">{loaded && pluralize(`Recipe`, count, true)}</p>
        <p className="description">{`Private recipes you've added`}</p>
      </section>
      {
        props.isProUser ? (
          <ActionBar className="fixed-width">
            <Link className="create-custom-recipe font-bold" href="/custom-recipe/add">
              <Image src={`${cdn.imagePrefix()}/custom-recipes/chef-hat.svg`} />
              {CREATE_YOUR_OWN_RECIPE}
            </Link>
          </ActionBar>
        ) : (
          <ActionBar className="fixed-width">
            <Button className="create-custom-recipe-locked" onClick={handleCustomRecipeLocked} label={CREATE_YOUR_OWN_RECIPE} iconCode={pstIcons.F0003_01C__Icon_lock}/>
          </ActionBar>
        )
      }
      {
        isEmpty ? (
          <section className="empty-cr-collection flex-column align-items-center">
            <Image src={`${cdn.imagePrefix()}/custom-recipes/empty-cr-collection.svg`} />
            <h3 className="empty-cr-title pst-h7">{`Get creative in the kitchen`}</h3>
            <p className="pst-p-small-regular">{`Preserve Grandma's recipes, create your own, or save those you found online and add them to your Meal Plan.`}</p>
          </section>
        ) : (
          <section className="recipe-list flex flex-wrap fixed-width">
            {
              recipes.map((recipe, i) => {
                // TODO post-MVP: full RecipeCard support?

                // Attach an intersection observer to the last card in the grid, which will trigger
                // fetching the next page when intersected upon
                const additionalProps = {};
                if (recipes.length === i + 1) {
                  additionalProps.ref = paginationRef;
                }
                return (
                  <div key={i} className="custom-recipe-card" {...additionalProps} >
                    <Link href={`/custom-recipe/${recipe.id}`}>
                      <div className="recipe-image" style={{
                        backgroundImage: `url("${recipe.imageURL || `${cdn.imagePrefix()}/custom-recipes/no-recipe-image.png`}")`,
                      }} />
                      <p className="recipe-name two-line-truncate p2-text font-normal">{recipe.title}</p>
                    </Link>
                  </div>
                );
              })
            }
          </section>
        )
      }
    </div>
  );
};

CustomRecipeCollection.propTypes = {
  customRecipes: YummlyPropTypes.customRecipes,
  fetchCustomRecipeList: YummlyPropTypes.action,
  isProUser: YummlyPropTypes.bool,
  showPaywallModal: YummlyPropTypes.action,
  username: YummlyPropTypes.string,
};

const mapStateToProps = state => {
  return {
    customRecipes: state.customRecipes,
    isProUser: isProUser(state),
    username: authUsernameSelector(state),
  };
};

const mapDispatchToProps = {
  fetchCustomRecipeList,
  showPaywallModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomRecipeCollection);
